import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import Banner from './../sections/banner';
import Feature from './../sections/feature';
import ScreenShot from './../sections/screenshot';
import VideoSection from './../sections/video-section';
import InterfaceSliderSection from './../sections/interface-slider-section';
import PricingSection from './../sections/pricing-section';
import TestimonialSection from './../sections/testimonial-section';
import FaqSection from './../sections/faq-section';
import BlogSection from '../sections/blog-section';
import ContactSection from '../sections/contact-section';
import Footer from '../sections/footer-section';

class AboutUs extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add(['sticky'], ['animated'], ['slideInDown']);
      } else {
        document.querySelector('.header--fixed').classList.remove(['sticky'], ['animated'], ['slideInDown']);
      }
    });
    return (
      <Fragment>
        <div className='App'>
          <Header headerStyle={'header header-style-1'}></Header>
          <br />
          <br />
          <br />
          <InterfaceSliderSection interFacesliderStyle={'interface-slider-section pt--60 pb--50 pt_lg--120'}></InterfaceSliderSection>
          <div className='container'>
            <p>
            From the aerospace and defense sectors to consumer electronics and telecommunications, PANEVIRA has the experience and expertise to support all industries. We understand that every industry has its own unique requirements and challenges, and we work closely with our clients to provide customized solutions that meet their specific needs.
            </p>
            <p>
            In the aerospace and defense sectors, our clients demand the highest levels of quality and reliability. PANEVIRA delivers top-quality electronic components and equipment that meet or exceed industry standards. We also provide customized solutions for critical applications, ensuring that our clients have the best possible products for their projects.
            </p>
            <p>
            In the consumer electronics and telecommunications sectors, our clients require fast delivery times and competitive pricing. PANEVIRA offers a wide selection of electronic components and equipment at competitive prices, with fast delivery options to help our clients meet their project deadlines.
            </p>
            <p>
            At PANEVIRA, we are committed to staying at the forefront of technological advancements and innovation. We continuously expand our product offerings and stay up-to-date with the latest advancements in the field of electronics. Our team of experts is always ready to provide technical support and consultation to help our clients stay ahead of the curve.
            </p>
          </div>
          <TestimonialSection testimonialStyle={'axsis-testimonial-section overflow-hidden pb--50 pt--50'}></TestimonialSection>
          <Footer footerStyle={'footer'}></Footer>
        </div>
      </Fragment>
    );
  }
}

export default AboutUs;
