import React, { Component, useEffect } from 'react';
import axios from 'axios';
import './../css/component/contact.css';

class ContactForm1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      part_no: '',
      manufacturer: '',
      quantity_required: '',
      cont_email: '',
      cont_company: '',
      remark:''
    };
  }


  handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    console.log('submit clicked');

    // Access the entered name from the component's state
    const inputField = {cont_email:this.state.cont_email, part_no:this.state.part_no, manufacturer:this.state.manufacturer, quantity_required:this.state.quantity_required, cont_company:this.state.cont_company,remark:this.state.remark  };
    console.log(inputField);

    const API_PATH = 'http://54.145.7.92/sendemail/common_react.php';
    console.log(API_PATH);


    // Make a POST request to a server (replace the URL with your server's endpoint)
    axios.post(API_PATH, inputField)
    .then((response) => {
     // Handle a successful response
     console.log('Data submitted:', response.data);
     this.setState({ submitted: true });
    })
    .catch((error) => {
     // Handle errors
     console.error('Error submitting data:', error);
    });

   

    //website_id:'1073'
    // Display an alert with the entered name
    // alert(`Hello, ${name}!`);
    
    // You can also perform other actions like sending the data to a server here
  }


  render() {
    return (
      <form className='contact-form' onSubmit={this.handleSubmit}>
        <div className='row'>
          <div className='col-lg-6 mb--30 mb-lg-0'>
            <input
              type='text'
              name='part_no'
              id='part_no'
              value={this.state.part_no}
              onChange={(e) => {
                this.setState({ part_no: e.target.value });
              }}
              placeholder='Part Number'
            />
            <input
              type='text'
              name='manufacturer'
              id='manufacturer'
              value={this.state.manufacturer}
              onChange={(e) => {
                this.setState({ manufacturer: e.target.value });
              }}
              placeholder='Manufacturer'
            />
            <input
              type='number'
              name='quantity_required'
              id='quantity_required'
              value={this.state.quantity_required}
              onChange={(e) => {
                this.setState({ quantity_required: e.target.value });
              }}
              placeholder='Quantity'
            />
            <input
              type='text'
              name='cont_email'
              id='cont_email'
              value={this.state.cont_email}
              onChange={(e) => {
                this.setState({ cont_email: e.target.value });
              }}
              placeholder='Your email'
            />
            <input
              type='text'
              name='cont_company'
              id='cont_company'
              value={this.state.cont_company}
              onChange={(e) => {
                this.setState({ cont_company: e.target.value });
              }}
              placeholder='Company Name'
            />
          </div>
          <div className='col-lg-6'>
            <textarea
              type='text'
              id='remark'
              name='remark'
              value={this.state.remark}
              onChange={(e) => {
                this.setState({ remark: e.target.value });
              }}
              placeholder='Your Message'
            />
          </div>
          <div className='col-12 text-center pt--50'>
            <button className='submit-btn' type='submit' value='submit' name='submit' id='mc-embedded-subscribe'>
              Send
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default ContactForm1;
