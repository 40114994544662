const BlogGridContent = [
    {
        imgUrl: "assets/images/blog/01.jpg",
        imgAlt: 'thumb1',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/02.jpg",
        imgAlt: 'thumb2',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/03.jpg",
        imgAlt: 'thumb3',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/01.jpg",
        imgAlt: 'thumb1',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/02.jpg",
        imgAlt: 'thumb2',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/03.jpg",
        imgAlt: 'thumb3',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/01.jpg",
        imgAlt: 'thumb1',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/02.jpg",
        imgAlt: 'thumb2',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    },
    {
        imgUrl: "assets/images/blog/03.jpg",
        imgAlt: 'thumb3',
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit......',
        btnText: 'Read More'
    }
]

export default BlogGridContent;