import React, { Component } from 'react';
import Testimonial from './../component/testimonial';
import SectionHeader from './../component/section-header';

// header section content
const title = <h3 className='mb-20'>What do clients think</h3>;
const subtitle = (
  <div>
    <p style={{ fontSize: 18 }} className='mb-20'>
    At PANEVIRA, we are proud to have a reputation for delivering exceptional service and support to our clients. Our customers have consistently praised our high-quality products, competitive pricing, and fast delivery options.
    </p>
    <p style={{ fontSize: 18 }} className='mb-20'>
      Here's what some of our clients have to say about us:
    </p>
  </div>
);
const alignment = 'section-header text-center pb--60';

class TeamSection extends Component {
  render() {
    return (
      <section id='testimonial' className={`${this.props.testimonialStyle ? this.props.testimonialStyle : ''}`}>
        <div className='container'>
          <SectionHeader title={title} alignment={alignment} subtitle={subtitle} />
        </div>
        <div className='container'>
          <Testimonial />
        </div>
      </section>
    );
  }
}

export default TeamSection;
