import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../../css/component/catagory.css';

const catagoryList = [
    {
        catagoryName: "Web Design",
        catagoryCount: '432'
    },
    {
        catagoryName: "Marketing",
        catagoryCount: '732'
    },
    {
        catagoryName: "WordPress",
        catagoryCount: '332'
    },
    {
        catagoryName: "Graphic Design",
        catagoryCount: '654'
    },
    {
        catagoryName: "Photography",
        catagoryCount: '124'
    },
    {
        catagoryName: "eCommerce",
        catagoryCount: '543'
    },
    {
        catagoryName: "HTML",
        catagoryCount: '32'
    }
    
]

class Catagory extends Component {
    render() {
        return (
            <ul className="catagory-list list-unstyled">
                {catagoryList.map((val, i)=>(
                    <li key={i}><Link className="d-flex align-items-center justify-content-between" to="/">
                        <span className="text">{val.catagoryName}</span>
                        <span className="count">{val.catagoryCount}</span>
                    </Link></li>
                ))}  
            </ul>
        )
    }
}

export default Catagory;