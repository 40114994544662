import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './../css/component/accordion.css';
// import 'react-accessible-accordion/dist/fancy-example.css';

const FaqList = [
  {
    question: 'Warranty ',
    answer:
      'PANEVIRA warrants for a period of one year after delivery that all purchased products will be free from any defects. This warranty can’t be applied in the event of misapplication or misuse of the product by buyer. ',
  },
  {
    question: 'Returns',
    answer:
      'Any cancellation prior to shipment must be approved by PANEVIRA. Any returns related to shipping errors, damaged goods or loss need to be reported within 9 days of the delivery date. All returns need to be made to PANEVIRA and need to be in resalable condition, together with the original invoice.',
  },
  {
    question: 'Disputes',
    answer:
      'Both parties are going to exercise their best intentions to resolve any disputes by negotiating them. All disputes that are not settled by negotiation will be decided in accordance with the Commercial Rules of the American Arbitration Association, and judgment will be entered on the award.',
  },
  {
    question: 'Tax',
    answer:
      'Any local tax will be charged on orders unless buyer offers PANEVIRA an exemption certificate. Check your tax-exempt status when placing the order.',
  },
];

class Accordion1 extends Component {
  render() {
    return (
      <Accordion preExpanded={'0'}>
        {FaqList.map((val, i) => (
          <AccordionItem className='faq' key={i}>
            <AccordionItemHeading className='faq-header'>
              <AccordionItemButton className='btn btn-link'>
                <span className='icon'>
                  <FaPlus className='fa-plus' />
                  <FaMinus className='fa-minus' />
                </span>
                <span className='text'>{val.question}</span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='faq-body'>
              <p>{val.answer}</p>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }
}

export default Accordion1;
