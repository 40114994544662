import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import Accordion1 from './../component/accordion';

// header section content
const title = <h3 className='mb--20'>Terms and Conditions</h3>;
const subtitle = <h6>Thanks for placing your order. The following terms and conditions are related to any transactions between you and PANEVIRA.</h6>;
const alignment = 'section-header text-center pb--60';

class FaqSection extends Component {
  render() {
    return (
      <section id='faq' className={`${this.props.faqStyle ? this.props.faqStyle : ''}`}>
        <div className='container p-0'>
          <div className='container-sm'>
            <SectionHeader title={title} subtitle={subtitle} alignment={alignment} />

            <div className='faq-container'>
              <Accordion1 />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FaqSection;
