import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import { FaRegListAlt, FaChartLine } from 'react-icons/fa';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { FiCheckCircle } from 'react-icons/fi';
import './../css/screenshot.css';

// section header 2 content const
const title2 = <h3 className='mb--15'>Why work with us?</h3>;
const subtitle2 = <h6 className='sub-title'>PANEVIRA is one of the leading distributors of electronic components in the US.</h6>;
const alignment2 = 'section-header text-lg-left pb--20';

const ssInfoList2 = [
  {
    icon: <FiCheckCircle />,
    description: 'We offer over 700,000 line items from a variety of manufacturers.',
  },
  {
    icon: <FiCheckCircle />,
    description: 'Most of our stock is available right away, and we have warehouses in the US, Europe, and Asia.',
  },
  {
    icon: <FiCheckCircle />,
    description: 'We have an experienced team ready to meet your expectations.',
  },
];

class ScreenShot1 extends Component {
  render() {
    return (
      <section id='screenshot' className={`${this.props.screenshotStyle ? this.props.screenshotStyle : ''}`}>
        <div className='container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='screenshot-content text-center text-lg-left'>
                <SectionHeader title={title2} subtitle={subtitle2} alignment={alignment2} />
                <div className='screenshot-info-container-style2 text-left'>
                  {ssInfoList2.map((val, i) => (
                    <div className='screenshot-info-item-style2 d-flex flex-wrap' key={i}>
                      <div className='icon'>{val.icon}</div>
                      <div className='content'>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-lg-7 pl_lg--100'>
              <div className='screenshot-image image2'>
                <img src='img/services-1.jpg' alt='images' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ScreenShot1;
