
import React, { Component } from 'react';
import './../css/get-ready.css'

const title = <h3>Ready to Get Started?</h3>;
const desc = <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.</p>
class GetReady extends Component {
    render() {
        return (
            <section class="get-ready-section pt--80 pb--80">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="div col-md-7 mb--30 mb-lg-0">
                            <div class="grs-content text-center text-lg-left">
                                {title}
                                {desc}
                            </div>
                        </div>
                        <div class="col-md-5 text-center text-lg-right">
                            <a href="#" class="grs-btn">Start Free Now</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default GetReady;