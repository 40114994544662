import React, { Component } from 'react';
import PlayStore from './../component/playstore';
import './../css/banner.css';
const bannerTitle = 'Your number one choice for the right components';
const bannerDesc =
  'At PANEVIRA, we understand the challenges of finding high-quality electronic components for your projects. Let us take the worry out of your search and provide you with the reliable and top-quality parts you need to bring your projects to life.';

class Banner extends Component {
  render() {
    return (
      <section id='banner' className={`${this.props.bannerStyle ? this.props.bannerStyle : ''}`}>
        <div className='banner-content d-flex align-items-center'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='banner-text'>
                  <h1>{bannerTitle}</h1>
                  <p>{bannerDesc}</p>
                  <PlayStore />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='banner-image-content d-flex align-items-center justify-content-center justify-content-lg-start'>
          <div className='banner-image pr--30 pl--30 pl-lg-0'>
            <div className='banner-joint-image'>
              <img className='img1' src='img/home-1.jpg' alt='shape1' />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Banner;
