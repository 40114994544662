import React, { Component } from 'react';
import { FaCloudUploadAlt, FaRegCommentAlt, FaRegImages } from 'react-icons/fa';
import SectionHeader from './../component/section-header';
import './../css/feature.css';

const FeatureList = [
  {
    icon: <FaRegCommentAlt />,
    title: '24/7 Support',
    description: 'At PANEVIRA, we are always ready to assist you with your needs. Our team is always here to answer your questions.',
  },
  {
    icon: <FaCloudUploadAlt />,
    title: 'Fast Shipping',
    description:
      'We know how important it is that your order reaches you on time. This is why we usually ship within 24-48 hours after the order is confirmed.',
  },
  {
    icon: <FaRegImages />,
    title: 'Quality Assurance',
    description: 'We partner with recognized suppliers that only create the best components in the industry.',
  },
  {
    icon: <span>✓</span>,
    title: 'Electronic Component Distribution ',
    description:
      "PANEVIRA distributes a wide range of high-quality electronic components from some of the industry's leading manufacturers. We offer a diverse product portfolio that includes capacitors, resistors, semiconductors, diodes, inductors, and more.",
  },
  {
    icon: <span>✓</span>,
    title: 'Prototyping and Testing',
    description:
      "We offer prototyping and testing services to ensure that our clients' electronic projects meet their performance and reliability requirements. Our team of experts can help you design, develop, and test your electronic components to ensure that they meet your specifications.",
  },
  {
    icon: <span>✓</span>,
    title: 'Inventory Management and Logistics',
    description:
      'PANEVIRA offers inventory management and logistics services to ensure that our clients have the electronic components they need when they need them. We can help you manage your inventory and provide just-in-time delivery options to ensure that your projects stay on track.',
  },
  {
    icon: <span>✓</span>,
    title: 'Supply Chain Management',
    description:
      'PANEVIRA provides comprehensive supply chain management services to help our clients optimize their electronic component procurement processes. Our team of experts can help you manage your vendor relationships, track inventory, and streamline your procurement process to ensure that you have the components you need when you need them.',
  },
  {
    icon: <span>✓</span>,
    title: 'Value-Added Services',
    description:
      "PANEVIRA offers a range of value-added services to help our clients save time and money on their electronic component projects. Our services include component kitting, custom labeling, and packaging, as well as a range of other services that are tailored to our clients' specific needs.",
  },
  {
    icon: <span>✓</span>,
    title: 'Environmental Compliance',
    description:
      'PANEVIRA is committed to environmental responsibility and compliance with all relevant regulations. We work with manufacturers that adhere to environmentally responsible practices and offer electronic components that are free from hazardous materials.',
  },
];

// header section content
const title = <h3 className='mb--25'>Our Services</h3>;
const subtitle = <h6>We take pride in providing the best services for our customers.</h6>;
const alignment = 'section-header text-center pb--60';

class Feature extends Component {
  render() {
    return (
      <section id='feature' className={`${this.props.featureStyle ? this.props.featureStyle : ''}`}>
        <div className='container'>
          <SectionHeader title={title} subtitle={subtitle} alignment={alignment} />
        </div>
        <div className='container'>
          <div className='row justify-content-center justify-content-lg-start'>
            {FeatureList.map((val, i) => (
              <div className='col-md-6 col-lg-4 mb--30 mb-lg-0' key={i}>
                <div className='feature-item text-left'>
                  <div className='icon-box'>
                    <div className='icon'>{val.icon}</div>
                    <img src='assets/images/feature/feature-dot-shape.png' alt='dot shape' />
                  </div>
                  <h5>{val.title}</h5>
                  <p>{val.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Feature;
