import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import { FaRegListAlt, FaChartLine } from 'react-icons/fa';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { FiCheckCircle } from 'react-icons/fi';
import './../css/screenshot.css';

// section header content const
const title = <h3 className='mb--15'>More about us</h3>;
const subtitle = <h6 className='sub-title'>Our team specializes in supply of electronic components all over the world. </h6>;
const alignment = 'section-header text-lg-left pb--40';

const ssInfoList1 = [
  {
    icon: <FaRegListAlt />,
    title: 'Easy Quotes',
    description: 'You just need to send us basic information regarding the components you need so we can create a quote.',
  },
  {
    icon: <FaChartLine />,
    title: 'Experienced Team',
    description: 'We have 30 years of combined experience among all the members of our team.',
  },
  {
    icon: <AiOutlineThunderbolt />,
    title: 'Key Partners',
    description: 'We work with many partners around the world to provide faster shipping and quality components. ',
  },
];

class ScreenShot extends Component {
  render() {
    return (
      <section id='screenshot' className={`${this.props.screenshotStyle ? this.props.screenshotStyle : ''}`}>
        <div className='container position-relative pt--30 pb--30 pt_lg--50 pb_lg--50'>
          <div className='row'>
            <div className='col-lg-5 order-lg-last'>
              <div className='screenshot-content text-center text-lg-left'>
                <SectionHeader title={title} subtitle={subtitle} alignment={alignment} />

                <div className='screenshot-info-container-style1 hover-effect-parent1 text-left'>
                  {ssInfoList1.map((val, i) => (
                    <div className='screenshot-info-item d-flex flex-wrap' key={i}>
                      <div className='icon'>{val.icon}</div>
                      <div className='content'>
                        <h6 className='font-weight-bold mb-2 mt-0'>{val.title}</h6>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-lg-7 order-lg-first pr_lg--100'>
              <div className='screenshot-image image1'>
                <img src='img/home-2.jpg' alt='screenshot' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ScreenShot;
