import React, { Component } from 'react';
import { Swiper, Pagination } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import './../css/component/testimonial.css';
import 'swiper/css/swiper.css';

const testimonialList = [
  {
    imagUrl: '4',
    imageAlt: 'author1',
    text: 'PANEVIRA was able to find the parts that I was looking for. Everything went great!',
    name: 'John Patrick',
    designation: 'Buyer',
  },
  {
    imagUrl: '5',
    imageAlt: 'author2',
    text: 'We were looking for a partner that could get products on time. PANEVIRA has been our number one choice.',
    name: 'Mia More',
    designation: 'Buyer',
  },
  {
    imagUrl: '6',
    imageAlt: 'author3',
    text: 'Fast and reliable. We are always happy with my orders from PANEVIRA.',
    name: 'Edwin Tomas',
    designation: 'Buyer',
  },
];

const params = {
  // Provide Swiper class as props
  Swiper,
  containerClass: 'axsis-testimonial-container',
  spaceBetween: 30,
  slidesPerView: 1,
  loop: true,
  // Add modules you need
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  },
};

class Testimonial extends Component {
  render() {
    return (
      <ReactIdSwiperCustom {...params}>
        {testimonialList.map((val, i) => (
          <div className='swiper-slide' key={i}>
            <div className='testimonial-body d-flex flex-wrap justify-content-between'>
              <div className='author-image'>
                <img src={`img/about-${val.imagUrl}.jpg`} alt={`${val.imageAlt}`} style={{ objectFit: 'fill' }} />
              </div>
              <div className='testimonial-content'>
                <div className='rating'>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                  <i className='fas fa-star'></i>
                </div>
                <p className='text'>{val.text}</p>
                <h6 className='name'>{val.name}</h6>
                <p className='designation mb-0'>{val.designation}</p>
              </div>
            </div>
          </div>
        ))}
      </ReactIdSwiperCustom>
    );
  }
}

export default Testimonial;
