import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import Banner2 from './../sections/banner2';
import ClientBrandSection from './../sections/client-brand-section'
import Feature2 from './../sections/feature2';
import ScreenShot2 from './../sections/screenshot2';
import GetApp from './../sections/get-app-section';
import InterfaceSliderSection from './../sections/interface-slider-section';
import PricingSection from './../sections/pricing-section';
import TestimonialSection from './../sections/testimonial-section';
import TeamSection from './../sections/team-section';
import BlogSection from '../sections/blog-section';
import ContactSection from '../sections/contact-section';
import Footer from '../sections/footer-section';

class Home2 extends Component {
  render(){
    window.addEventListener('scroll', function() {
      var value = window.scrollY;
      if (value > 100) {
          document.querySelector('.header--fixed').classList.add(['sticky'], ['animated'], ['slideInDown'])
      }else{
          document.querySelector('.header--fixed').classList.remove(['sticky'], ['animated'], ['slideInDown'])
      }
    });
    return (
        <Fragment> 
            <div className="App">
                <Header headerStyle={'header header-style-2'}></Header>
                <Banner2 bannerStyle={'banner-section bg-ash-color2 style2'}></Banner2>
                <ClientBrandSection clientSectionStyle={'client-logo-section bg-ash-color2 pb--60 pt--60 pt-lg-20'}></ClientBrandSection>
                <Feature2 featureStyle={'feature-section roundShapeleft bg-ash-color2 pt--60 pb--5 pt_lg--100 pb_lg--100'}></Feature2>
                <ScreenShot2 screenshotStyle={'screenshot-section bg-ash-color2 roundShaperight circleShapeleft pt--15 pb--5 pt_lg--70 pb_lg--70'}></ScreenShot2>
                <GetApp getAppStyle={'get-app-section pt--50 pb--50 pt_lg--100 pb_lg--100'}></GetApp>
                <InterfaceSliderSection interFacesliderStyle={'interface-slider-section pt--60 pb--50 pt_lg--120'}></InterfaceSliderSection>
                <PricingSection pricingStyle={'pricing-section pt--50 pb--50 style2'}></PricingSection>
                <TestimonialSection testimonialStyle={'axsis-testimonial-section overflow-hidden pb--50 pt--50 style2'}></TestimonialSection>
                <TeamSection teamStyle={'axsis-team-section pt--60 pb--60 pt_lg--100 pb_lg--80'}></TeamSection>
                <BlogSection blogStyle={'blog-section pt--50 pb--40 pt_lg--100 style2'}></BlogSection>
                <ContactSection contactStyle={'contact-section style2'}></ContactSection>
                <Footer footerStyle={'footer'}></Footer>
            </div>
        </Fragment> 
    );
  }
}

export default Home2;