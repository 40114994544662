import React, { Component } from 'react';
import PlayStore from './../component/playstore';
import './../css/component/get-app.css';


class GetApp extends Component {
    
    render() {
        return (
            <section className={`${this.props.getAppStyle ? this.props.getAppStyle : ""}`}>
                <div className="container">
                    <div className="col-lg-5 text-center text-lg-left">
                        <h3 className="mb-3 text-light">Get App Now</h3>
                        <p className="text-light">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. commligula eget dolor. Aenan massa. pretium quis, sem. </p>
                        <PlayStore />
                    </div>
                </div>
                <div className="get-image d-none d-lg-block">
                    <img 
                        src="assets/images/getapp-bg.png" 
                        alt="getapp"
                    />
                </div>
            </section>
        )
    }
}

export default GetApp;