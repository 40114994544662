const BlogListContent = [
    {
        images: "../assets/images/blog-page/01.jpg",
        title: 'Nullam dictum felis eu pede mollis pretium',
        desc: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit rum.'
    },
    {
        images: "assets/images/blog-page/02.jpg",
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit2......',
        desc: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit rum.'
    },

    {
        images: "assets/images/blog-page/01.jpg",
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit3......',
        desc: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit rum.'
    },
    {
        images: "assets/images/blog-page/02.jpg",
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit4......',
        desc: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit rum.'
    },
    {
        images: "assets/images/blog-page/01.jpg",
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit5...... ',
        desc: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit rum.'
    },
    {
        images: "assets/images/blog-page/02.jpg",
        title: 'Lorem ipsum dolor sit amet, con sectetuer adipiscing elit6......',
        desc: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit rum.'
    },
]

export default BlogListContent;