import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import Banner from './../sections/banner';
import Feature from './../sections/feature';
import ScreenShot from './../sections/screenshot';
import VideoSection from './../sections/video-section';
import InterfaceSliderSection from './../sections/interface-slider-section';
import PricingSection from './../sections/pricing-section';
import TestimonialSection from './../sections/testimonial-section';
import FaqSection from './../sections/faq-section';
import BlogSection from '../sections/blog-section';
import ContactSection from '../sections/contact-section';
import Footer from '../sections/footer-section';

class App extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add(['sticky'], ['animated'], ['slideInDown']);
      } else {
        document.querySelector('.header--fixed').classList.remove(['sticky'], ['animated'], ['slideInDown']);
      }
    });
    return (
      <Fragment>
        <div className='App'>
          <Header headerStyle={'header header-style-1'} color='white'></Header>
          <Banner bannerStyle={'banner-section'}></Banner>
          <div className='container'>
            <p>
              Welcome to PANEVIRA, your trusted supplier of high-quality electronic components and equipment. Our extensive inventory includes
              semiconductors, capacitors, resistors, inductors, and more, all sourced from top manufacturers and rigorously tested to ensure their
              quality and reliability.
            </p>
            <p>
              At PANEVIRA, we are committed to providing you with exceptional service and support to ensure your success with our products. Our team
              of experts is always ready to assist you in finding the right components for your specific needs, and we offer customized solutions for
              your electronic requirements.
            </p>
            <p>
              We understand the importance of a reliable supply chain for your business. That's why we offer inventory management and logistics
              support to help streamline your operations and reduce lead times.
            </p>
            <p>
              With competitive pricing and flexible payment options to fit any budget, we are your go-to supplier for all of your electronic needs.
              Whether you're a hobbyist or a large corporation, we have the products and services to help you succeed.
            </p>
            <p>
              Thank you for choosing PANEVIRA as your trusted partner in the electronics industry. Contact us today to learn more about our products
              and services.
            </p>
          </div>
          <ScreenShot screenshotStyle={'screenshot-section angleShape bg-ash-color pt--60 pb--60 pt_lg--70 pb_lg--70'}></ScreenShot>
          {/* <VideoSection VideoSectionStyle={'video-section text-center'}></VideoSection>
                <InterfaceSliderSection interFacesliderStyle={'interface-slider-section pt--60 pb--50 pt_lg--120'}></InterfaceSliderSection>
                <PricingSection pricingStyle={'pricing-section pt--50 pb--50'}></PricingSection>
                <TestimonialSection testimonialStyle={'axsis-testimonial-section overflow-hidden pb--50 pt--50'}></TestimonialSection>
                <FaqSection faqStyle={'axsis-faq-section pt--50 pb--50'}></FaqSection>
                <BlogSection blogStyle={'blog-section pt--50 pb--40'}></BlogSection>
                <ContactSection contactStyle={'contact-section'}></ContactSection> */}
          <Footer footerStyle={'footer'}></Footer>
        </div>
      </Fragment>
    );
  }
}

export default App;
