import React, { Component } from 'react';
import SectionHeader from '../component/section-header';
import ContactForm1 from './../component/contact-form1';
import '../css/contact-section.css';

// header section content
const title = <h3>Request a Quote</h3>;
const alignment = 'section-header text-center pb--60';

class ContactSection extends Component {
  render() {
    return (
      // <section id="contact" className="contact-section">
      <section id='contact' className={`${this.props.contactStyle ? this.props.contactStyle : ''}`} style={{marginTop:100}}>
        <div className='container'>
          <div className='contact-wrapper'>
            <SectionHeader title={title} alignment={alignment} />
            <div className='contact'>
              <ContactForm1 />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSection;
