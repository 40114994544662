
import React, { Component } from 'react';
import { Swiper, Pagination } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import './../css/component/testimonial.css';
import 'swiper/css/swiper.css';

const testimonialList = [
    {
        imagUrl: "01",
        imageAlt: 'author1',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ',
        name: 'John Bordir',
        designation: 'Designer'
    },
    {
        imagUrl: "02",
        imageAlt: 'author2',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ',
        name: 'Tomas More',
        designation: 'Developer'
    },
    {
        imagUrl: "03",
        imageAlt: 'author3',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ',
        name: 'Kadre John',
        designation: 'Photographer'
    },
    {
        imagUrl: "01",
        imageAlt: 'Nil Johny',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ',
        name: 'John Bordir',
        designation: 'Designer'
    },
    {
        imagUrl: "03",
        imageAlt: 'Charl Nelson',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ',
        name: 'John Bordir',
        designation: 'Designer'
    },
    {
        imagUrl: "02",
        imageAlt: 'author6',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. ',
        name: 'David Clark',
        designation: 'Developer'
    }
]

const params = {
    // Provide Swiper class as props
    Swiper,
    containerClass: 'axsis-testimonial-container2',
    spaceBetween: 60,
    slidesPerView: 1,
    loop: true,
    // Add modules you need
    modules: [Pagination],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  }

class Testimonial2 extends Component {
    render() {
        return (
            <ReactIdSwiperCustom {...params}>
                {testimonialList.map((val , i) => (
                    <div className="swiper-slide" key={i}>
                        <div class="testimonial-body">
                            <div class="author-image">
                            <img
                                    src={`assets/images/testimonial/${val.imagUrl}.png`} alt={`${val.imageAlt}`}
                                 />
                            </div>
                            <div class="testimonial-content">
                                <h6 className="name">{val.name}</h6>
                                <p className="designation mb-30">{val.designation}</p>
                                <p className="text">{val.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </ReactIdSwiperCustom>
        )
    }
}

export default Testimonial2;