import React, { Component } from 'react';
import SectionHeader from './../component/section-header';
import InterfaceSlider from './../component/interface-slider';
import './../css/component/interface-slider.css';

// header section content
const title = <h3 className='mb--20'>We work with different industries</h3>;
const subtitle = <h6>PANEVIRA has worked with different clients from all industries.</h6>;
const alignment = 'section-header text-center pb--60';
class InterfaceSliderSection extends Component {
  render() {
    return (
      <section className={`${this.props.interFacesliderStyle ? this.props.interFacesliderStyle : ''}`}>
        <SectionHeader title={title} subtitle={subtitle} alignment={alignment} />
        <div className='interface-slider'>
          <InterfaceSlider></InterfaceSlider>
        </div>
      </section>
    );
  }
}

export default InterfaceSliderSection;
