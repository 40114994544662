import React, { Component, Fragment } from 'react';
import './../css/app.css';
import Header from './../sections/header';
import Banner from './../sections/banner';
import Feature from './../sections/feature';
import ScreenShot from './../sections/screenshot';
import VideoSection from './../sections/video-section';
import InterfaceSliderSection from './../sections/interface-slider-section';
import PricingSection from './../sections/pricing-section';
import TestimonialSection from './../sections/testimonial-section';
import FaqSection from './../sections/faq-section';
import BlogSection from '../sections/blog-section';
import ContactSection from '../sections/contact-section';
import Footer from '../sections/footer-section';
import ScreenShot1 from '../sections/screenshot1';

class Services extends Component {
  render() {
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add(['sticky'], ['animated'], ['slideInDown']);
      } else {
        document.querySelector('.header--fixed').classList.remove(['sticky'], ['animated'], ['slideInDown']);
      }
    });
    return (
      <Fragment>
        <div className='App'>
          <Header headerStyle={'header header-style-1'}></Header>
          <br />
          <br />
          <br />
          <Feature featureStyle={'feature-section pt--60 pb--60 pt_lg--100 pb_lg--100'}></Feature>
          <ScreenShot1 screenshotStyle={'screenshot-section angleShape bg-ash-color pt--60 pb--60 pt_lg--70 pb_lg--70'}></ScreenShot1>
          {/* <Feature featureStyle={'feature-section pt--60 pb--60 pt_lg--100 pb_lg--100'}></Feature>
          <VideoSection VideoSectionStyle={'video-section text-center'}></VideoSection>
          <InterfaceSliderSection interFacesliderStyle={'interface-slider-section pt--60 pb--50 pt_lg--120'}></InterfaceSliderSection>
          <PricingSection pricingStyle={'pricing-section pt--50 pb--50'}></PricingSection>
          <TestimonialSection testimonialStyle={'axsis-testimonial-section overflow-hidden pb--50 pt--50'}></TestimonialSection>
          <FaqSection faqStyle={'axsis-faq-section pt--50 pb--50'}></FaqSection>
          <BlogSection blogStyle={'blog-section pt--50 pb--40'}></BlogSection>
          <ContactSection contactStyle={'contact-section'}></ContactSection> */}
          <Footer footerStyle={'footer'}></Footer>
        </div>
      </Fragment>
    );
  }
}

export default Services;
