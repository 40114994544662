import React, { Component } from 'react';
import Team from './../component/team';
import SectionHeader from './../component/section-header';

// header section content
const title = <h3 className="mb-20">Meet Our Team</h3>;
const subtitle = <h6>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo<br />justo. Nullam dictum felis eu pede mollis pretium.</h6>;
const alignment = 'section-header text-center pb--60';

class TestimonialSection extends Component {
    render() {
        return (
            <section id="team" className={`${this.props.teamStyle ? this.props.teamStyle : ""}`}>
                <div className="container">
                    <SectionHeader 
                        title={ title }
                        subtitle={ subtitle }
                        alignment={alignment}
                    />
                </div>
                <div className="container">
                    <Team /> 
                </div>
            </section>
        )
    }
}

export default TestimonialSection;