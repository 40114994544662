import React, { Component } from 'react';
import PlayStore from './../component/playstore';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../css/footer.css';

class Footer extends Component {
  render() {
    return (
      <footer className={`${this.props.footerStyle ? this.props.footerStyle : ''}`}>
        <div className='footer-top pt--100 pt_sm--120 pb--40 pb_lg--90'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-widget text-center text-lg-left mb--30 mb-lg-0'>
                  <Link to='/' className='footer-logo'>
                    <img src='img/logo-h.png' alt='logo' width={'50%'} />
                  </Link>
                  <ul className='footer-contact-info pl-0 list-unstyled'>
                    <li>
                      <a target='_top'>
                        At PANEVIRA, we are committed to providing our customers with the highest quality electronic components and equipment. Our
                        team of experts is dedicated to delivering exceptional service and support, ensuring your success with our products. We take
                        pride in our extensive inventory, competitive pricing, and flexible payment options to fit any budget. Thank you for choosing
                        PANEVIRA as your trusted partner in the electronics industry. Contact us today to learn more about our products and services.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-3 col-lg-3 pl-lg-5'>
                <div className='footer-widget text-center text-lg-left mb--30 mb-lg-0'>
                  <h5 className='footer-title'>Quick Links</h5>
                  <ul className='list-ustyled pl-0 pb-0 list-unstyled'>
                    <li>
                      <Link to='/'>Home</Link>
                    </li>
                    <li>
                      <Link to='/about'>About Us</Link>
                    </li>
                    <li>
                      <Link to='/services'>Services</Link>
                    </li>
                    <li>
                      <Link to='/contact'>Get Quote</Link>
                    </li>
                    <li>
                      <Link to='/terms'>Terms & Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-5 col-lg-5 pl-lg-5'>
                <div className='footer-widget text-center text-lg-left mb--30 mb-lg-0'>
                  <h5 className='footer-title'>Contact Us</h5>
                  <ul className='list-ustyled pl-0 pb-0 list-unstyled'>
                    <li>Email: qualitycontrol@panevira.com</li>
                    <li>
                      Location: South Tower, Sampoerna Strategic Square, Jl. Jend. Sudirman No. Kav. 45-46, Jakarta, DKI Jakarta 12930, Indonesia
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='footer-bottom'>
          <div className='container'>
            <div className='d-flex justify-content-between align-items-center'>
              <p>
                &copy; Copyrights 2023 <Link to='/'>PANEVIRA</Link> All rights reserved.
              </p>
              <p>
                Made width <span className='ti-heart'></span>by <Link to='/'> PANEVIRA </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
