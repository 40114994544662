import React, { Component } from 'react';
import './../css/component/playstore.css';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import { Link } from 'react-router-dom';

class PlayStore extends Component {
  render() {
    return (
      <div className='play-store'>
        <Link to='/contact' className='custom-btn d-inline-flex align-items-center'>
          <p>
            <span>Get a Quote</span>
          </p>
        </Link>
      </div>
    );
  }
}

export default PlayStore;
